import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PaymentService from '@/services/payment.service'
import InfoCreditCard from '../../Payment/AddPayment/Common/InfoCreditCard/InfoCreditCard.vue'
import EventBus from '@/util/EventBus'
import { Payment } from '@/models/payments/Payment'
import SubscriptionPlanService from '@/services/subscription-plan.service'
import { getParameterByName } from '@/util/GetQueryString'

export default {
  name: 'RetrySubscriptionRenewPayment',
  components: {
    HeaderTopDashboard,
    InfoCreditCard,
  },
  props: {
  },
  data () {
    return {
      payment: new Payment(),
      plan: null,
      planType: null,
      loading: false,
      showRedirect: false,
      cant: 1,
      subTotalSetup: 0,
      subTotalFee: 0,
      subTotalIva: 0,
      promo: '',
      promotional_code: '',
    }
  },
  watch: {
    payment: {
      handler: function () {
        this.calculateSubtotals()
      },
      deep: true,
    },
    locale () {
      // const params = {
      //   promo: this.promo,
      //   amount: this.payment.invoiceTmp?.amount || this.payment.amount,
      // }
      // PaymentService.checkPromotionalCode(params)
      // .then(
      //   (response) => {
      //     this.promotional_code = response.promo
      //   },
      //   () => {
      //     this.promotional_code = null
      //     EventBus.$emit('showAlert', 'danger', this.$t('El código promocional no es válido'))
      //   },
      // )
    },
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    paymentIvaLine () {
      const vatDivision = this.payment.paymentDetail?.infoBilling?.billingProfiles[0]?.vatDivision
      if (vatDivision) {
        let line = ''
        for (const [vatDivisionName, vatDivisionValue] of Object.entries(vatDivision)) {
          line += vatDivisionName + ' ' + vatDivisionValue + '% + '
        }
        return line.slice(0, -2)
      } else {
        return this.payment.invoiceTmp.pc_iva + '% IVA'
      }
    },
  },
  methods: {
    addPromoCode () {
      // const params = {
      //   promo: this.promo,
      //   amount: this.payment.invoiceTmp?.amount || this.payment.amount,
      // }
      // PaymentService.checkPromotionalCode(params)
      // .then(
      //   (response) => {
      //     this.promotional_code = response.promo
      //     this.confimCreditCardPaymentMethod()
      //   },
      //   () => {
      //     this.promotional_code = null
      //     EventBus.$emit('showAlert', 'danger', this.$t('El código promocional no es válido'))
      //   },
      // )
    },
    calculateSubtotals () {
      this.calculateSubtotalsFee(
        this.payment.fee,
        this.cant,
        this.payment.invoiceTmp.iva,
      )
    },
    calculateSubtotalsFee (amount, cant, iva) {
      this.subTotalFee = parseFloat(cant) * parseFloat(amount)
      this.subTotalIva += (parseFloat(this.subTotalFee) * parseFloat(iva)) / 100
    },
    confimCreditCardPaymentMethod () {
      const data = {
        amount: this.parseAmount(this.payment.amount),
        infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
        subscribed_card: this.payment.creditCardOptions.subscribed_card,
        rememberCreditCard: this.payment.creditCardOptions.rememberCreditCard,
        promo: this.promo,
        invoiceTmpId: this.payment.invoiceTmp.id,
        pathOK: '/users/plans',
        pathKO: '/users/plans',
      }
      PaymentService.confimCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoiceTmp = response.invoiceTmp
            this.payment.urlCreditCardPay = response.url
          },
          (error) => {
            console.log('error', error)
          },
        )
    },
    viewUrlPay () {
      this.loading = true
      this.showRedirect = true
      setTimeout(function (scope) {
        scope.loading = false
        window.location.href = scope.payment.urlCreditCardPay
      }, 1000, this)
    },
    cancelStep () {
      this.$router.push({ name: 'plans' })
    },
    /* *********************************************************************************************************** */
    getPaymentMethods () {
      PaymentService.getPaymentMethods()
        .then(
          (response) => {
            this.payment.activeCreditCard = response.activeCreditCard
            this.payment.activeMultibanco = response.activeMultibanco
            this.payment.activeBoleto = response.activeBoleto
            this.payment.activePaypal = response.activePaypal
            this.payment.activePlan = response.activePlan
            this.payment.activeSubscription = response.activeSubscription
            this.payment.activeTransfer = response.activeTransfer
            this.payment.billingProfiles = response.billingProfiles
            this.payment.currency = response.currency
            this.payment.fromPlans = response.fromPlans
            this.payment.paymentsInfo = response.paymentsInfo
            this.payment.show = true
            this.getPaymentInvoice()
            this.getCreditCardPaymentMethod()
          },
          () => {},
        )
        .finally(() => {})
    },
    getPaymentInvoice () {
      const token = getParameterByName('token')
      const paymentMethod = getParameterByName('paymentMethod')

      if (token && (parseInt(paymentMethod) === this.payment.paymentMethod.creditCard)) {
        this.getPaymentInvoiceCreditCard(token)
      }
    },
    getPaymentInvoiceCreditCard (token) {
      const data = {
        token: token,
      }

      PaymentService.callbackCreditCardPaymentMethod(data)
        .then(
          (response) => {
            this.payment.invoice = response.invoice
            this.payment.paymentMethodSelectedId = this.payment.paymentMethod.creditCard
            this.payment.complete = true
            this.payment.showInvoice = true
            // this.payment.step = 2

            this.getUserBalance()
          },
          (error) => {
            if (error.response.status === 422) {
              EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar añadir plan'))
            }
            EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.code))
          },
        )
        .finally(() => {})
    },
    getCreditCardPaymentMethod () {
      this.loadingCreditCard = true
      PaymentService.getCreditCardPaymentMethod()
        .then(
          (response) => {
            this.payment.paymentDetail = response
            this.payment.defaultCreditCardId = response.subscribedCards.length > 0 ? response.subscribedCards[0].id : '0'
          },
          // (error) => {
          //   PaymentService.errorResponse(
          //     error.response.status,
          //     this.$t('Error al intentar añadir plan'),
          //     this.$t(error.response.data.code),
          //   )
          // },
        )
        .finally(() => {
          this.loadingCreditCard = false
          this.confirmSubscriptionRenew()
        })
    },
    confirmSubscriptionRenew () {
      // const data = {
      //   amount: this.parseAmount(this.payment.amount),
      //   infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
      //   subscribed_card: this.payment.creditCardOptions.subscribed_card,
      //   rememberCreditCard: this.payment.creditCardOptions.rememberCreditCard,
      //   promo: this.promo,
      //   invoiceTmpId: this.payment.invoiceTmp.id,
      // }
      const data = {
        plan: this.planType,
        subscription: true,
        infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
        subscribed_card: this.payment.creditCardOptions.subscribed_card,
        promo: this.promo,
        userPlanId: this.plan.userPlanId,
        invoiceTmpId: this.payment.invoiceTmp.id,
        pathOK: '/users/plans',
        pathKO: '/users/plans',
      }
      SubscriptionPlanService.confirmSubscriptionRenew(data).then(
        (response) => {
          this.payment.setup = response.setup
          this.payment.fee = response.fee
          this.payment.invoiceTmp = response.invoiceTmp
          this.payment.urlCreditCardPay = response.url
        },
        (error) => {
          console.log('error', error)
        },
      )
    },
  },
  /* *********************************************************************************************************** */
  mounted () {
    this.plan = this.$route.params.sub
    this.planType = this.$route.params.planType
    console.log('plan', this.plan, 'planType', this.planType)
    this.getPaymentMethods()
  },
}
